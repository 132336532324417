import { mapToCampaignAds, mapToCampaingnPopup } from "@mappers/PlateFormat/mapper";
import { RestyleCombinationFinderProps } from "@models/RestyleCombinationFinder/RestyleCombinationFinder.models"
import theme from "@styles/theme";
import { getVehicleTypeById } from "@utils/Helpers"
import { mapToButton } from "@utils/mapper"

export const mapToRestyleCombinationFinder = (data: any): RestyleCombinationFinderProps => {    
    const restyleOptions = data.restyle_options.linked_items.map((restyleOptionData: any) => {
        const restyleOption = restyleOptionData.elements;
        return {
            name: restyleOption.title.value,
            description: restyleOption.description.value,
            basePrice: restyleOption.base_price.value,
            plateImageUrl: restyleOption.example_image.value.length > 0 ? restyleOption.example_image.value[0].url : '',
            formatType: restyleOption.format_type.value[0].name,
        }
    });

    const campaignItems = data.campaign_items.linked_items.map((item: any) => {
        return {
            name: item.elements.campaign_name.value,
            validFrom: item.elements.valid_from.value,
            expiresOn: item.elements.expire_on.value,
            transactionTypes: item.elements.transaction_type.value,
            campaignPopup: item.elements.popup && item.elements.popup.linked_items.length > 0 ? mapToCampaingnPopup(item.elements.popup.linked_items[0]) : null,
            ads: item.elements.ads && item.elements.ads.linked_items.length > 0 ? mapToCampaignAds(item.elements.ads.linked_items[0]) : null,
        }
    })

    return {
        availableMessage: data.available_message.value,
        headingAlignment: "center",
        button: mapToButton(data),
        heading: data.heading.value,
        restyleGuideMessage: data.restyle_guide_message.value,
        restyleOptions: restyleOptions,
        subHeading: data.sub_heading.value,
        unavailableMessage: data.unavailable_message.value,
        alternativeMessage:data.alternative_message.value,
        apiUrl: '',
        queryParameters: {
            combination: '',
            vehicleType: getVehicleTypeById(1)
        },
        vehicleTheme: theme.vehicleTheme.white,
        reCaptchaSiteKey: "",
        reCaptchaSiteKeyV2: "",
        campaigns: campaignItems,
        emailValidationToken: ""
    }
}

export const mapToCampaignItems = (data: any): any => { 
    
    if (data.nodes.length > 0){
        return {
            name: data.nodes[0].elements.campaign_name.value,
            validFrom: data.nodes[0].elements.valid_from.value,
            expiresOn: data.nodes[0].elements.expire_on.value,
            transactionTypes: data.nodes[0].elements.transaction_type.value,
            campaignPopup: data.nodes[0].elements.popup && data.nodes[0].elements.popup.linked_items.length > 0 ? mapToCampaingnPopup(data.nodes[0].elements.popup.linked_items[0]) : null,
            ads: data.nodes[0].elements.ads && data.nodes[0].elements.ads.linked_items.length > 0 ? mapToCampaignAds(data.nodes[0].elements.ads.linked_items[0]) : null,
        }
    } else {
        return null;
    }
}
