import React from "react"
import { graphql } from "gatsby"
import {Layout} from "@components/Layout"
import {SEO,mapToSEO} from '@components/SEO'
import { Grid } from "semantic-ui-react"
import { RestyleCombinationFinder } from "@components/CombinationFinder"
import { getVehicleTypeByQueryString, getCombinationByQueryString, getCleanCampaignByQueryString } from "@utils/Helpers"
import { VehicleType, VehicleTypesSetting } from "@models/ProductModels"
import { DefaultCombination } from "@utils/Constant"
import { GenericBannerContainer } from "@components/GenericBannerContainer"
import { mapToRestyleCombinationFinder } from "@mappers/RestyleCombinationFinder/RestyleCombinationFinder.mapper"
import { LOCALSTORAGE_CAMPAIGN_CURRENT } from "@redux/localStorage"
const queryString = require('query-string');
var get = require('lodash/get');

type RedesignState = {
  vehicleType: VehicleType,
  combination: string,
  cleanCampaign: boolean
}

const loadFromQuerystring = (location: any):RedesignState =>{
  if(location.search){
    const query = queryString.parse(location.search,{parseBooleans: true, parseNumbers: true});
    const vehicleType = getVehicleTypeByQueryString(query.vt);
    const combination = getCombinationByQueryString(query.combination);
    const cleanCampaign = getCleanCampaignByQueryString(query.clean)

    return {
      vehicleType:vehicleType,
      combination: combination,
      cleanCampaign: cleanCampaign
    };
  }
  return {
    vehicleType: VehicleTypesSetting.Car,
    combination:'',
    cleanCampaign: false
  }
}

const loadCombinationFromState = (combination:string, vehicleType: VehicleType) =>{
  if(combination === get(DefaultCombination, vehicleType.name)){
    return '';
  }
  return combination;
}

const loadFromStates = (states:any):RedesignState | undefined =>{
  if(states && states.vehicleType){
    return {
      vehicleType: states.vehicleType,
      combination: loadCombinationFromState(states.combination, states.vehicleType),
      cleanCampaign: states.cleanCampaign
    }
  }
  return undefined;
}

const RedesignPage = (props:any) => {

    const {location} = props;
    const {elements} = props.data.kontentItemRedesignPage;
    const apiUrl = props.data.site.siteMetadata.apiUrl;
    const restyleCombinationFinderData = mapToRestyleCombinationFinder(elements.restyle_combination_finder.linked_items[0].elements)
    const seo = mapToSEO(elements);

    const states = loadFromStates(location.state);
    const queryData = states === undefined ? loadFromQuerystring(location) : states;

    restyleCombinationFinderData.headingAlignment = 'left';
    restyleCombinationFinderData.queryParameters.combination = queryData.combination;
    restyleCombinationFinderData.queryParameters.vehicleType = queryData.vehicleType;
    restyleCombinationFinderData.apiUrl = apiUrl;
    restyleCombinationFinderData.reCaptchaSiteKey = props.data.site.siteMetadata.reCaptchaSiteKey;
    restyleCombinationFinderData.reCaptchaSiteKeyV2 = props.data.site.siteMetadata.reCaptchaSiteKeyV2;
    restyleCombinationFinderData.emailValidationToken = props.data.site.siteMetadata.emailValidationToken;
    if (queryData.cleanCampaign){
      const campaignInLocal: string | null = localStorage.getItem(LOCALSTORAGE_CAMPAIGN_CURRENT);
      if(campaignInLocal) {
        localStorage.removeItem(LOCALSTORAGE_CAMPAIGN_CURRENT); 
      }     
    }

    return (<Layout version="simple" currentStep={1} location={props.location}>
        <SEO {...seo} />
            <GenericBannerContainer
              padding={{
                mobile: {
                    top: 30,
                    bottom: 190
                },
                desktop: {
                    top: 50,
                    bottom: 80
                }
              }}
              backgroundColor='black;'>
                <Grid>
                  <Grid.Row>
                    <Grid.Column width={16}>
                    <RestyleCombinationFinder {...restyleCombinationFinderData} darkTheme={true}/>
                    </Grid.Column>
                    <Grid.Column width={6}></Grid.Column>
                  </Grid.Row>
                </Grid>
            </GenericBannerContainer>
  </Layout>);

}

export const query = graphql`
{
  site {
    siteMetadata {
      apiUrl
      emailValidationToken
      reCaptchaSiteKey
      reCaptchaSiteKeyV2
    }
  }
  kontentItemRedesignPage{
    elements {
      heading{
        value
      }
      subheading {
        value
      }
      restyle_combination_finder {
        linked_items {
          ... on KontentItemRestyleCombinationFinder {
            id
            elements {
              available_message {
                value
              }
              button__button_color_theme {
                value {
                  codename
                  name
                }
              }
              button__button_type {
                value {
                  codename
                  name
                }
              }
              button__text {
                value
              }
              button__url_slug {
                value
              }
              heading {
                value
              }
              restyle_guide_message {
                value
              }
              restyle_options {
                linked_items {
                  ... on KontentItemProductCategoryFormats {
                    id
                    elements {
                      base_price {
                        value
                      }
                      description {
                        value
                      }
                      example_image {
                        value {
                          url
                          description
                        }
                      }
                      format_type {
                        value {
                          name
                          codename
                        }
                      }
                      title {
                        value
                      }
                    }
                  }
                }
              }
              sub_heading {
                value
              }
              unavailable_message {
                value
              }
              alternative_message{
                value
              }
              campaign_items {
                linked_items {
                  ... on KontentItemSweepstakeCampaign {
                    elements {
                      campaign_name {
                        value
                      }
                      expire_on {
                        name
                        type
                        value
                      }
                      valid_from {
                        name
                        type
                        value
                      }                        
                      transaction_type {
                        value {
                          name
                          codename
                        }
                      }                        
                      ads {
                        linked_items {
                          ... on KontentItemCampaignAds {
                            elements {
                              left_title_1 {
                                value
                              }
                              left_title_2 {
                                value
                              }
                              left_description {
                                value
                              }
                              left_picture {
                                value {
                                  name
                                  description
                                  url
                                  width
                                  height
                                }
                              }
                              right_title {
                                value
                              }
                              right_description_1 {
                                value
                              }
                              right_description_2 {
                                value
                              }
                              right_description_3 {
                                value
                              }
                              left_background_colors {
                                value {
                                  name
                                  codename
                                }
                              }
                              right_background_colors {
                                value {
                                  name
                                  codename
                                }
                              }
                            }
                          }
                        }
                      }
                      popup {
                        linked_items {
                          ... on KontentItemCampaignPopup {
                            elements {
                              header {
                                value
                              }
                              header_campaign_name{
                                value
                              }
                              picture {
                                value {
                                  name
                                  description
                                  url
                                  width
                                  height
                                }
                              }                              
                              form_title {
                                value
                              }
                              email_opt_in_text{
                                value
                              }
                              footer {
                                value
                              }
                              terms___conditions{
                                value
                              }
                              background_color {
                                value {
                                  name
                                  codename
                                }
                              }
                              response {
                                value
                              }
                              input_field_background_color {
                                value {
                                  name
                                  codename
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      plate_formats {
        linked_items {
          ... on KontentItemProductCategoryFormats {
            id
            elements {
              base_price {
                value
              }
              description {
                value
              }
              format_type {
                value {
                  codename
                  name
                }
              }
              example_image {
                value {
                  description
                  url
                }
              }
              title {
                value
              }
            }
          }
        }
      }
      page_info___checkout__title{
        value
      }
      page_info___checkout__progress{
        value{
          codename
          name
        }
      }
      restyle_guide{
        value
      }
      available_mesg{
        value
      }
      unavilable_restyle_guide{
        value
      }
      unavailable_mesg{
        value
      }
      seo__noindex {
        value {
          name
          codename
        }
      }
      seo__nofollow {
        value {
          name
          codename
        }
      }
      seo__page_title {
        value
      }
      seo__page_description {
        value
      }
      url {
        value
      }
    }
  }
}
`

export default RedesignPage;