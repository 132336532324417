import { Campaign, CampaignAds, CampaignPopup } from "@models/Campaign/Campaign";
import { KenticoCampaign, KenticoCampaignAds, KenticoCampaignPopup } from "@models/Campaign/KenticoCampaign";
import { KenticoPlateFormat, PlateFormat } from "@models/index";

export const mapToPlateFormatList = (data:any): PlateFormat[] =>{
    return data.linked_items.map((plateFormat:KenticoPlateFormat) => mapToPlateFormat(plateFormat));
}

export const mapToSweepstakeCampaignList = (data:any): Campaign[] =>{
    return data.linked_items.map((campaign:KenticoCampaign) => mapToSweepstakeCampaign(campaign));
}

export const mapToPlateFormat = (data:KenticoPlateFormat): PlateFormat =>{
    const {elements} = data;
    return{
        name: elements.title.value,
        basePrice: elements.base_price.value,
        description: elements.description.value,
        formatType: elements.format_type.value.length > 0 ? elements.format_type.value[0].name : '',
        plateImageUrl: elements.example_image.value.length > 0 ? elements.example_image.value[0].url : ''
    }
}

export const mapToSweepstakeCampaign = (data:KenticoCampaign) : Campaign => {
    const {elements} = data;
    return{
        name: elements.campaign_name.value,
        validFrom: elements.valid_from.value,
        expiresOn: elements.expire_on.value,        
        transactionTypes: elements.transaction_type.value,
        campaignPopup: elements.popup && elements.popup.linked_items.length > 0 ? mapToCampaingnPopup(elements.popup.linked_items[0]) : null,
        ads: elements.ads && elements.ads.linked_items.length > 0 ? mapToCampaignAds(elements.ads.linked_items[0]) : null,
    }
}

export const mapToCampaingnPopup = (data: KenticoCampaignPopup) : CampaignPopup => {
    const {elements} = data;
    return {
        header:  elements.header.value,
        header_campaign_name: elements.header_campaign_name.value,
        picture: elements.picture.value.length > 0 ? elements.picture.value : undefined,
        email_opt_in_text: elements.email_opt_in_text.value,
        form_title: elements.form_title.value,
        terms___conditions: elements.terms___conditions.value,
        footer: elements.footer.value,
        background_color: elements.background_color,
        input_field_background_color: elements.input_field_background_color,
        response: elements.response.value,
    }
}

export const mapToCampaignAds = (data: KenticoCampaignAds) : CampaignAds => {
    const {elements} = data;
    return {
        left_title_1: elements.left_title_1.value,
        left_title_2: elements.left_title_2.value,
        left_description: elements.left_description.value,
        left_picture: elements.left_picture.value.length > 0 ? elements.left_picture.value : undefined,
        right_title: elements.right_title.value,
        right_description_1: elements.right_description_1.value,
        right_description_2: elements.right_description_2.value,
        right_description_3: elements.right_description_3.value,
        left_background_colors: elements.left_background_colors,
        right_background_colors: elements.right_background_colors
    }
}